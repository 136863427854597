@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TheSans";
  src: url("../public/fonts/TheSans.otf");
}

@font-face {
  font-family: "Switzer-Light";
  src: url("../public/fonts/Switzer-Light.otf");
}

@font-face {
  font-family: "Switzer-Medium";
  src: url("../public/fonts/Switzer-Medium.otf");
}

@font-face {
  font-family: "Switzer-Bold";
  src: url("../public/fonts/Switzer-Bold.otf");
}

@font-face {
  font-family: "Switzer-Light-Italic";
  src: url("../public/fonts/Switzer-LightItalic.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 .led-text-strong {
  animation: pulsatestrong 1.5s ease-in-out infinite alternate;
}

h1 .led-text-weak {
  animation: pulsateweak 1.5s ease-in-out infinite alternate;
}

@keyframes pulsatestrong {
  100% {
    text-shadow: 0 0 0px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #5271ff,
      0 0 80px #5271ff, 0 0 90px #5271ff
  }

  0% {
    text-shadow: 0 0 0px #fff, 0 0 10px #fff, 0 0 18px #fff, 0 0 38px #5271ff,
      0 0 73px #5271ff, 0 0 80px #000c42
  }
}

@keyframes pulsateweak {
  100% {
    text-shadow: 0 0 0px #fff, 0 0 6px #fff, 0 0 15px #fff, 0 0 20px #5271ff,
      0 0 60px #5271ff, 0 0 70px #5271ff
  }

  0% {
    text-shadow: 0 0 0px #fff, 0 0 8px #fff, 0 0 16px #fff, 0 0 20px #5271ff,
      0 0 60px #5271ff, 0 0 70px #000c42
  }
}


.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.fade-up-title {
  opacity: 0;
  /* Start with the text hidden */
  animation: fadeUpAnimation 0.9s ease-in-out forwards;
  /* Apply the animation */
}

.fade-up-subtitle {
  opacity: 0;
  /* Start with the text hidden */
  animation: fadeUpAnimation 1.2s ease-in-out forwards;
  /* Apply the animation */
}

.fade-up-button {
  opacity: 0;
  /* Start with the text hidden */
  animation: fadeUpAnimation 1.4s ease-in-out forwards;
  /* Apply the animation */
}

@keyframes fadeUpAnimation {
  from {
    opacity: 0;
    /* Start the animation with 0 opacity */
    transform: translateY(70px);
    /* Move the text 20px down */
  }

  to {
    opacity: 1;
    /* End the animation with full opacity */
    transform: translateY(0);
    /* Reset the text position */
  }
}

.logo-glow {
  z-index: 1;
  filter: brightness(0) invert(1) blur(5px);
  -webkit-filter: brightness(0) invert(1) blur(5px);
  opacity: 1;
}


.globe-video {
  transition: transform 2.4s ease;
  transform: scale(1);
}

@media (max-width: 467px) {
  .globe-video {
    transform: scale(1);
  }
}

@media (min-width: 767px) {
  .globe-video {
    transform: scale(1);
  }

  .globe-video:hover {
    transform: scale(1.1);
  }

}

.swiss-text {
  background: url("https://www.montaguebikes.com/wp-content/uploads/2018/03/swiss-flag-wide.png") center center;
  background-size: contain;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-family: Catamaran, sans-serif;
  padding: 0;
  margin: 0;
  text-align: center;
}

.social-icon:hover {
  color: theme('colors.highlight1') !important;
}

#tsparticles {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -10;
}

.glow-button {
  color: white;
}

.glow-button:hover {
  box-shadow: 0 1px 25px theme('colors.highlight1');
}

/* CSS */
.line-break {
  white-space: pre-wrap;
}

.wrapper {
  display: grid;
  place-items: center
}

.glassy {
  background: rgba(1, 171, 253, 0.19);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.1px);
  -webkit-backdrop-filter: blur(11.1px);
  border: 1px solid rgba(1, 171, 253, 0.3);
}